import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { Global } from '@emotion/core';
import { injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl'
import Helmet from 'react-helmet';

const Faq = ({ intl }) => (
  <>
    <Global
      styles={theme => ({
        body: {
          fontFamily: theme.fonts.body,
        }
      })}
    />
    <Layout>
      <SEO title={intl.formatMessage({id: 'faq'})} />
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <Helmet>
            <link rel="alternate" hrefLang="x-default" href="https://couponise.app/faq/" />
            {languages.map(language => {
              if(language !== intl.defaultLocale) {
                return (
                  <link key={language} rel="alternate" hrefLang={`${language}`} href={`https://couponise.app/${language}/faq/`} />
                )
              }
            })}
          </Helmet>
        )}
      </IntlContextConsumer>
      <div className="pt-23 pt-md-26 pt-lg-31 pb-13 pb-md-18 pb-lg-23">
        <div className="container">
          {/* Section Title */}
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9">
              <div className="text-center mb-13 mb-lg-18">
                <h1 className="font-size-11 mb-7">
                  {intl.formatMessage({id: 'faq'})}
                </h1>
              </div>
            </div>
          </div>
          {/* End Section Title */}
          {/* Faqs */}
          {/* Faqs */}
          <div className="row justify-content-center">
            {/* Single Faq */}
            <div className="col-lg-6 col-md-10">
              <div className="d-flex mb-10 mb-lg-17">
                <div className="mr-6">
                  <div className="bg-couponise circle-28 text-white mt-1">
                    <i className="fa fa-question" />
                  </div>
                </div>
                <div className="">
                  <h4 className="font-size-7 mb-7">
                    { intl.formatMessage({id: 'what_is_benefit'}) }
                  </h4>
                  <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    { intl.formatMessage({id: 'what_is_benefit_text'}) }
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Faq */}
            {/* Single Faq */}
            <div className="col-lg-6 col-md-10">
              <div className="d-flex mb-10 mb-lg-17">
                <div className="mr-6">
                  <div className="bg-couponise circle-28 text-white mt-1">
                    <i className="fa fa-question" />
                  </div>
                </div>
                <div className="">
                  <h4 className="font-size-7 mb-7">
                    { intl.formatMessage({id: 'how_can_people_join'}) }
                  </h4>
                  <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    { intl.formatMessage({id: 'how_can_people_join_text'}) }
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Faq */}
            {/* Single Faq */}
            <div className="col-lg-6 col-md-10">
              <div className="d-flex mb-10 mb-lg-17">
                <div className="mr-6">
                  <div className="bg-couponise circle-28 text-white mt-1">
                    <i className="fa fa-question" />
                  </div>
                </div>
                <div className="">
                  <h4 className="font-size-7 mb-7">
                    { intl.formatMessage({id: 'what_is_loyalty'}) }
                  </h4>
                  <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    { intl.formatMessage({id: 'what_is_loyalty_text'}) }
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Faq */}
            {/* Single Faq */}
            <div className="col-lg-6 col-md-10">
              <div className="d-flex mb-10 mb-lg-17">
                <div className="mr-6">
                  <div className="bg-couponise circle-28 text-white mt-1">
                    <i className="fa fa-question" />
                  </div>
                </div>
                <div className="">
                  <h4 className="font-size-7 mb-7">
                    { intl.formatMessage({id: 'what_is_referral'}) }
                  </h4>
                  <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    { intl.formatMessage({id: 'what_is_referral_text'}) }
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Faq */}
            {/* Single Faq */}
            <div className="col-lg-6 col-md-10">
              <div className="d-flex mb-10 mb-lg-17">
                <div className="mr-6">
                  <div className="bg-couponise circle-28 text-white mt-1">
                    <i className="fa fa-question" />
                  </div>
                </div>
                <div className="">
                  <h4 className="font-size-7 mb-7">
                    { intl.formatMessage({id: 'how_can_ref_members'}) }
                  </h4>
                  <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    { intl.formatMessage({id: 'how_can_ref_members_text'}) }
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Faq */}
            {/* Single Faq */}
            <div className="col-lg-6 col-md-10">
              <div className="d-flex mb-10 mb-lg-17">
                <div className="mr-6">
                  <div className="bg-couponise circle-28 text-white mt-1">
                    <i className="fa fa-question" />
                  </div>
                </div>
                <div className="">
                  <h4 className="font-size-7 mb-7">
                    { intl.formatMessage({id: 'how_can_coupons_purchases'}) }
                  </h4>
                  <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    { intl.formatMessage({id: 'how_can_coupons_purchases_text'}) }
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Faq */}
          </div>
          {/* Button  */}
          <div className="text-center pt-lg-3">
            <p className="font-size-6 mb-0">
              { intl.formatMessage({id: 'still_have_a_question'}) }
              <a
                className="text-blue-3 btn-link-with-underline ml-5"
                href="mailto:hello@couponise.app"
              >
                { intl.formatMessage({id: 'contact_us'}) }
              </a>
            </p>
          </div>
          {/* Button End */}
        </div>
      </div>
    </Layout>
  </>
);

export default injectIntl(Faq);
